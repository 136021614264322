import Block from '../block'

export default class Wishlist extends Block {
	static title = 'wishlist'

	constructor($el) {
		super($el)

		this.added = !!this.$el.data('wishlistProductId')

		this.$el.click(() => {
			if (this.$el.data('authenticated')) {
				if (this.added) {
					this.remove()
				} else {
					this.add()
				}
			} else {
				$(document).trigger(
					'message:error',
					`<span>You have to be logged in to add an item to your wishlist, please go to <a href="/account">account</a></span>.`,
				)
				this.$el.find('.checkbox__input').prop('checked', false)
			}
		})

		this.data = new FormData()
		this.data.append('product_id', this.$el.data('productId'))
		this.data.append('ajax', true)

		$(document).on('color:change', (event, data) => {
			this.data.set('color_id', data.id)
		})

		$(document).on('size:change', (event, data) => {
			this.data.set('size_id', data.id)
		})
	}

	setLoading() {
		// if (loading) {
		// 	this.$el.findElement('loader').removeModifier('hidden')
		// 	this.$el.findElement('icon').addModifier('hidden')
		// } else {
		// 	this.$el.findElement('loader').addModifier('hidden')
		// 	this.$el.findElement('icon').removeModifier('hidden')
		// }
	}

	add() {
		// this.setLoading(true)

		if (this.$el.data('notify')) {
			this.data.append('notify', true)
		}

		this.data.append('wishlist_add', true)
		this.data.delete('wishlist_remove')
		this.data.append('option_id', this.$el.data('optionId'))

		$.ajax({
			method: 'POST',
			processData: false,
			contentType: false,
			cache: false,
			url: '/account',
			data: this.data,
		})
			.done(this.handleSuccess)
			.fail(this.handleFailure)
			.always(() => this.setLoading(false))
	}

	remove() {
		// this.setLoading(true)

		this.data.append('wishlist_product_id', this.$el.data('wishlistProductId'))
		this.data.append('wishlist_delete', true)
		this.data.delete('wishlist_add')

		$.ajax({
			method: 'POST',
			processData: false,
			contentType: false,
			cache: false,
			url: '/account',
			data: this.data,
		})
			.done(this.handleSuccess)
			.fail(this.handleFailure)
			.always(() => this.setLoading(false))
	}

	handleFailure(error) {
		$(document).trigger('message:error', error.message)
	}

	handleSuccess(json) {
		if (typeof json.error === 'string') {
			$(document).trigger('message:error', json.error)
		}

		if (typeof json.message === 'string') {
			$(document).trigger('product:add-to-wishlist')
			$(document).trigger('message:success', json.message)
		}

		// window.location.reload()
	}
}
