import { MDCSelect } from '@material/select'
import Block from '../../block'

export default class MdcSelect extends Block {
	static title = 'mdc-select--webiq'

	constructor($el) {
		super($el)

		this.select = new MDCSelect(this.$el.get(0))

		const width = this.$el.find('mdc-floating-label').width()

		this.select.foundation_.adapter_.notchOutline(width)
		this.select.foundation_.adapter_.floatLabel(true)
	}
}
