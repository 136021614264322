import Block from '../../common.blocks/block'
import $ from 'jquery'

export default class ProductOptions extends Block {
	static title = 'product-options'

	constructor($el) {
		super($el)

		this.checkboxes = this.$el.find('input[type="radio"]')
		this.indicator = this.$el.findElement('indicator')
		this.select = this.$el.findElement('select')
		this.checkedIcon = this.$el.findElement('checked--icon')

		$(document).on('size:change', () => this.changeSelectPrices())

		this.checkboxes.each((index, el) => {
			if ($(el).is(':checked')) {
				this.indicator.text($(el).data('title'))
				this.select.text(`Geselecteerd: ${$(el).data('title')}`)
			}

			$(el).on('change', () => {
				this.loading(true)

				if ($(el).is(':checked')) {
					this.indicator.text($(el).data('title'))
					this.select.text(`Geselecteerd: ${$(el).data('title')}`)
				}

				this.data = new FormData()
				this.data.append('related_set', true)
				this.data.append(
					'set_id',
					$(el).parent('.product-options__label').data('setId'),
				)
				this.data.append(
					'product_set_id',
					$(el).parent('.product-options__label').data('productSetId'),
				)
				this.data.append(
					'product_id',
					$(el).parent('.product-options__label').data('productId'),
				)
				this.data.append('ajax', true)

				$.ajax({
					method: 'POST',
					processData: false,
					contentType: false,
					cache: false,
					url: '/shoppingcart',
					data: this.data,
				})
					.done(() => {
						this.$el.find('.accordion').addModifier('hidden')
						this.$el.find('.accordion__container').height(0)

						$(document).trigger('option:change')
					})
					.always(() => {
						this.loading(false)
					})
			})
		})
	}

	changeSelectPrices() {
		this.$el.findElement('label').each((index, el) => {
			const mainPrice = this.$el.data('productPrice')
			const productPrice = $('.size-select__input:checked').data('price')
			const input = $(el).find('.product-options__input').data('extraPrice')
			const diff = parseFloat(productPrice) - parseFloat(mainPrice)

			if (parseFloat(productPrice) - parseFloat(mainPrice) > 0) {
				$(el)
					.find('.product-options__price--price')
					.text(parseFloat(mainPrice) + parseFloat(input) + diff)
			} else {
				$(el)
					.find('.product-options__price--price')
					.text(parseFloat(mainPrice) + parseFloat(input) - diff)
			}
		})
	}

	loading(status) {
		if (status) {
			this.checkedIcon.addClass('fa-spinner')
			this.checkedIcon.addClass('fa-spin')
		} else {
			this.checkedIcon.removeClass('fa-spinner')
			this.checkedIcon.removeClass('fa-spin')
		}
	}
}
