import Block from '../../common.blocks/block'

export default class StockStatus extends Block {
	static title = 'stock-status'

	constructor($el) {
		super($el)

		this.date = new Date()
		this.message = this.$el.findElement('message')

		if (this.$el.data('type') == 'product') {
			$(document).on('size:change', (event, data) => {
				this.changeStatus(data)
			})
		}

		if (this.$el.data('type') == 'overview') {
			const data = {
				stock: this.$el.data('stock'),
				outlet: this.$el.data('outlet'),
				availability: this.$el.data('availability'),
			}
			this.changeStatus(data)
		}

		if (this.$el.data('type') == 'extended') {
			const data = {
				stock: this.$el.data('stock'),
				outlet: this.$el.data('outlet'),
				availability: this.$el.data('availability'),
			}
			this.changeStatus(data)
		}
	}

	changeStatus(data) {
		if (data.outlet) {
			if (data.stock > 0 || data.availability == 'presale') {
				if (this.$el.data('delivery')) {
					this.message.text(this.$el.data('delivery'))
					this.message.addClass('stock-status__message--success')
				} else {
					if (this.$el.data('deliveryLocation')) {
						this.message.text(this.daytimeRule())
						this.message.addClass('stock-status__message--success')
					} else {
						this.message.text('Proefrijden? Plan direct uw afspraak in')
						this.message.addClass('stock-status__message--success')
					}
				}
			} else {
				this.message.text('Bel 0318-241001 voor een alternatief')
				this.message.addClass('stock-status__message--error')
			}
		} else {
			if (data.stock > 0 || data.availability == 'presale') {
				if (this.$el.data('delivery')) {
					this.message.text(this.$el.data('delivery'))
					this.message.addClass('stock-status__message--success')
				} else {
					if (data.stock < 4 && data.availability != 'presale') {
						this.message.text('Voorraad beperkt, bestel snel')
						this.message.addClass('stock-status__message--warning')
					} else {
						this.message.text(this.daytimeRule())
						this.message.addClass('stock-status__message--success')
					}
				}
			} else {
				this.message.text('Vraag de levertijd op')
				this.message.addClass('stock-status__message--error')
			}
		}
	}

	daytimeRule() {
		const startToday = 0
		const endToday = 15 * 60
		const startTomorrow = 15 * 60 + 1
		const endTomorrow = 23 * 60 + 59

		const now = this.date.getHours() * 60 + this.date.getMinutes()
		const day = this.date.getDay()

		if (this.$el.data('deliveryLocation')) {
			if (this.$el.data('deliveryLocation') == '210-2161') {
				// 5 - 7
				if (day < 6) {
					if (day == 5) {
						return `Vandaag besteld, tussen ${this.getNextDays(
							9,
						)} en ${this.getNextDays(11)} in huis.`
					}

					if (now >= startToday && now <= endToday) {
						return `Vandaag besteld, tussen ${this.getNextDays(
							5,
						)} en ${this.getNextDays(7)} in huis.`
					}

					if (now >= startTomorrow && now <= endTomorrow) {
						return `Vandaag besteld, tussen ${this.getNextDays(
							6,
						)} en ${this.getNextDays(8)} in huis.`
					}
				}

				if (day == 6) {
					return `Vandaag besteld, tussen ${this.getNextDays(
						8,
					)} en ${this.getNextDays(10)} in huis.`
				}

				if (day == 7) {
					return `Vandaag besteld, tussen ${this.getNextDays(
						7,
					)} en ${this.getNextDays(9)} in huis.`
				}
			} else if (this.$el.data('deliveryLocation') == '210-2162') {
				// 5 - 10
				if (day < 6) {
					if (day == 5) {
						return `Vandaag besteld, tussen ${this.getNextDays(
							9,
						)} en ${this.getNextDays(14)} in huis.`
					}

					if (now >= startToday && now <= endToday) {
						return `Vandaag besteld, tussen ${this.getNextDays(
							5,
						)} en ${this.getNextDays(10)} in huis.`
					}

					if (now >= startTomorrow && now <= endTomorrow) {
						return `Vandaag besteld, tussen ${this.getNextDays(
							6,
						)} en ${this.getNextDays(10)} in huis.`
					}
				}

				if (day == 6) {
					return `Vandaag besteld, tussen ${this.getNextDays(
						8,
					)} en ${this.getNextDays(13)} in huis.`
				}

				if (day == 7) {
					return `Vandaag besteld, tussen ${this.getNextDays(
						7,
					)} en ${this.getNextDays(12)} in huis.`
				}
			} else if (this.$el.data('deliveryLocation') == '210-2263') {
				// 1 - 2
				if (day < 6) {
					if (day == 5) {
						return `Vandaag besteld, tussen ${this.getNextDays(
							5,
						)} en ${this.getNextDays(6)} in huis.`
					}

					if (now >= startToday && now <= endToday) {
						return `Vandaag besteld, tussen ${this.getNextDays(
							1,
						)} en ${this.getNextDays(2)} in huis.`
					}

					if (now >= startTomorrow && now <= endTomorrow) {
						return `Vandaag besteld, tussen ${this.getNextDays(
							2,
						)} en ${this.getNextDays(3)} in huis.`
					}
				}

				if (day == 6) {
					return `Vandaag besteld, tussen ${this.getNextDays(
						3,
					)} en ${this.getNextDays(4)} in huis.`
				}

				if (day == 7) {
					return `Vandaag besteld, tussen ${this.getNextDays(
						2,
					)} en ${this.getNextDays(3)} in huis.`
				}
			}
		} else {
			if (day < 6) {
				if (day == 5) {
					if (now >= startTomorrow && now <= endTomorrow) {
						const tuesday = this.getNextDays(4)
						return `Vandaag besteld, ${tuesday} in huis`
					}
				}

				if (now >= startToday && now <= endToday) {
					const tomorrow = this.getNextDays(1)
					return `Vandaag besteld, ${tomorrow} in huis`
				}

				if (now >= startTomorrow && now <= endTomorrow) {
					const dayAfter = this.getNextDays(2)
					return `Vandaag besteld, ${dayAfter} in huis`
				}
			}

			if (day == 6) {
				const tuesday = this.getNextDays(3)
				return `Vandaag besteld, ${tuesday} in huis`
			}

			if (day == 7) {
				const tuesday = this.getNextDays(2)
				return `Vandaag besteld, ${tuesday} in huis`
			}
		}
	}

	getNextDays(days) {
		return new Date(
			this.date.getFullYear(),
			this.date.getMonth(),
			this.date.getDate() + days,
		).toLocaleDateString('NL-nl', {
			weekday: 'long',
			day: 'numeric',
			month: 'long',
		})
	}
}
