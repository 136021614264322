import { MDCTextField, MDCTextFieldFoundation } from '@material/textfield'
import Block from '../../block'

export default class MdcTextField extends Block {
	static title = 'mdc-text-field--webiq'

	constructor($el) {
		super($el)

		this.field = new MDCTextField(this.$el.get(0))
		this.foundation = new MDCTextFieldFoundation(this.$el.get(0))

		this.foundation.init()

		this.autofillChecks = 0
		this.width = this.$el.find('mdc-floating-label').width()

		if (this.$el.hasClass('mdc-text-field--webiq--forced')) {
			window.setTimeout(() => {
				this.$el
					.find('.mdc-notched-outline__notch')
					.attr('style', `width: ${this.width};`)
			}, 300)
		}

		if (
			this.$el
				.find('.mdc-text-field__input, .mdc-text-field__textarea')
				.attr('autofill') === 'off'
		) {
			window.setTimeout(() => this.checkAutofill(), 300)
		}
	}

	floatLabel(bool) {
		this.field.foundation_.adapter_.notchOutline(this.width)
		this.field.foundation_.adapter_.floatLabel(bool)
	}

	checkAutofill() {
		try {
			if (this.$el.find('input:-webkit-autofill')) {
				this.floatLabel(true)
			} else if (this.autofillChecks < 10) {
				this.autofillChecks = this.autofillChecks + 1
				window.setTimeout(() => this.checkAutofill(), 300)
			}
		} catch (e) {
			// fail silently
		}
	}
}
