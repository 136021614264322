import $ from 'jquery'

import Block from '../../common.blocks/block'

export default class SizeSelect extends Block {
	static title = 'size-select'

	constructor($el) {
		super($el)

		if (this.$el.hasModifier('radio')) {
			$(document).on('color:change', (event, data) =>
				this.handleColorChangeInput(event, data),
			)

			if (!this.$el.hasClass('size-select--hidden')) {
				this.handleChangeInput({
					target: {
						value: this.$el.find('input[name=size_radio]:checked').val(),
						checked: false,
					},
				})
			}

			this.$el
				.findElement('input')
				.on('change', (event) => this.handleChangeInput(event))
		} else {
			this.dropdown = this.$el.findElement('dropdown')
			this.stockIndicator = this.$el.findElement('stock')

			// if an user selects a new color, we want to display the corresponding size select
			$(document).on('color:change', (event, data) =>
				this.handleColorChange(event, data),
			)

			if (!this.$el.hasClass('size-select--hidden')) {
				this.handleChange({
					target: {
						value: this.dropdown.find(':selected').val(),
						checked: false,
					},
				})
			}

			this.dropdown.change((event) => this.handleChange(event))

			if (!this.$el.hasModifier('hidden')) {
				this.handleStockIndicatorChange()
			}
		}
	}

	handleColorChangeInput(event, data) {
		const inputs = this.$el.find('.size-select__input[data-available="true"]')
		if (data.id == this.$el.data('colorId')) {
			this.$el.removeModifier('hidden')

			inputs.prop('disabled', false)

			inputs.each((index, el) => {
				if (index == 0) {
					$(el).prop('checked', true)
				} else {
					$(el).prop('checked', false)
				}
			})

			this.handleChangeInput(event)
		} else {
			this.$el.addModifier('hidden')
			this.$el.find('.size-select__input').prop('disabled', true)
		}
	}

	handleChangeInput(event) {
		let input = this.$el.find('input[name=size_radio]:checked')
		let value = event.target.value

		if (input.length === 0) {
			return
		}

		if (!value) {
			value = input.val()
		}

		$(document).trigger('size:change', {
			id: value,
			name: this.$el.find('input[name=size_radio]:checked').data('title'),
			price: this.$el.find('input[name=size_radio]:checked').data('price'),
			advice: this.$el
				.find('input[name=size_radio]:checked')
				.data('priceAdvice'),
			stock: this.$el.find('input[name=size_radio]:checked').data('stock'),
			outlet: this.$el.find('input[name=size_radio]:checked').data('outlet'),
			availability: this.$el
				.find('input[name=size_radio]:checked')
				.data('availability'),
			remove: !event.target.checked,
		})
	}

	handleColorChange(event, data) {
		if (data.id == this.$el.data('colorId')) {
			this.$el.removeModifier('hidden')
			this.dropdown.prop('disabled', false)
		} else {
			this.$el.addModifier('hidden')
			this.dropdown.prop('disabled', true)
		}

		if (!this.$el.hasModifier('hidden')) {
			this.handleChange(event)
		}
	}

	handleChange(event) {
		let value = event.target.value
		if (!value) {
			value = this.$el.findElement('dropdown').val()
		}

		$(document).trigger('size:change', {
			id: value,
			name: this.dropdown.find(':selected').data('title'),
			price: this.dropdown.find(':selected').data('price'),
			advice: this.dropdown.find(':selected').data('priceAdvice'),
			stock: this.dropdown.find(':selected').data('stock'),
			outlet: this.dropdown.find(':selected').data('outlet'),
			remove: !event.target.checked,
		})

		this.handleStockIndicatorChange()
	}

	handleStockIndicatorChange() {
		const stockValue = this.dropdown.find(':selected').data('stock')
		if (stockValue > 0) {
			const stockText = this.stockIndicator.data('inStock')
			this.stockIndicator.text(stockText)
			$(document).trigger('product:inStock', {
				status: true,
			})
		} else {
			const stockText = this.stockIndicator.data('soldOut')
			this.stockIndicator.text(stockText)
			$(document).trigger('product:inStock', {
				status: false,
			})
		}
	}
}
