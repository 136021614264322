import $ from 'jquery'

/**
 * Retrieves the base BEM class of a class list
 *
 * @param {string}
 * @returns {string}
 */
const getBase = (element) => {
	const classes = $(element).attr('class').split(' ')

	if (classes.length === 0) {
		throw 'can only operate on BEM blocks'
	}

	return classes[0] ?? ''
}

/**
 * Adds a modifier to the given element
 *
 * @param {string} modifier
 * @returns {jQuery}
 *
 * @example
 * ```javascript
 * $('.popup').addModifier('active')
 * ```
 * This will result in the classlist `popup popup--active`
 */
$.fn.addModifier = function (modifier) {
	return $(this).addClass(`${getBase(this)}--${modifier}`)
}

/**
 * Removes a modifier to the given element
 *
 * @param {string} modifier
 * @returns {jQuery}
 *
 * @example
 * ```javascript
 * $('.popup').removeModifier('active')
 * ```
 * This will result in the classlist `popup`, if the classlist was `popup popup--active` before
 */
$.fn.removeModifier = function (modifier) {
	return $(this).removeClass(`${getBase(this)}--${modifier}`)
}

/**
 * Toggles a modifier
 *
 * @param {string} modifier
 * @returns {jQuery}
 *
 * @example
 * ```javascript
 * $('.popup').toggleModifier('active')
 * ```
 */
$.fn.toggleModifier = function (modifier) {
	return $(this).toggleClass(`${getBase(this)}--${modifier}`)
}

/**
 * Checks if the given element has a modifier
 *
 * @param {string} modifier
 * @returns {boolean}
 */
$.fn.hasModifier = function (modifier) {
	return $(this).hasClass(`${getBase(this)}--${modifier}`)
}

/**
 * Finds the element of a given block
 *
 * @param {string} element
 * @returns {jQuery}
 *
 * @example
 * ```html
 * <div class="popup">
 *   <div class="popup__content">
 *     Hello world
 *   </div>
 * </div>
 * ```
 * ```javascript
 * $('.popup').findElement('content')
 * ```
 */
$.fn.findElement = function (element) {
	return $(this).find(`.${getBase(this)}__${element}`)
}
