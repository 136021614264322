import $ from 'jquery'

import Block from '../block'

export default class ShoppingcartGiftcard extends Block {
	static title = 'shoppingcart-giftcard'

	constructor($el) {
		super($el)

		$(document).trigger('shoppingcart:view-cart')
	}
}
