import Block from '../../common.blocks/block'
// import $ from 'jquery'

export default class StickyBar extends Block {
	static title = 'sticky-bar'

	constructor($el) {
		super($el)

		// this.$el.findElement('item--arrow').on('click', () => {
		// 	$(document).scrollTop($(document).height())
		// })

		// $(window).on('scroll', () => {
		// 	if ($(window).scrollTop() + $(window).height() == $(document).height()) {
		// 		this.$el
		// 			.findElement('item--arrow')
		// 			.addClass('sticky-bar__item--flipped')
		// 			.on('click', () => {
		// 				$(document).scrollTop(0)
		// 			})
		// 	} else {
		// 		this.$el
		// 			.findElement('item--arrow')
		// 			.removeClass('sticky-bar__item--flipped')
		// 			.on('click', () => {
		// 				$(document).scrollTop($(document).height())
		// 			})
		// 	}
		// })
	}
}
