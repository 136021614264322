import Block from '../../common.blocks/block'
import $ from 'jquery'

export default class Compare extends Block {
	static title = 'compare'

	constructor($el) {
		super($el)

		this.checkbox = this.$el.findElement('checkbox')
		this.rmAll = this.$el.findElement('rm-all')
		this.count = this.$el.findElement('count')
		// set form
		this.data = new FormData()
		this.data.append('product_id', this.$el.data('productId'))
		this.data.append('ajax', true)

		this.checkbox.on('change', () => this.toggleCompare())
		this.rmAll.on('click', () => this.removeAll())

		$(document).on('compare:rm-all', () => this.removeCarousel())
		$(document).on('product:compare-count', (_event, count) =>
			this.updateCount(count),
		)
	}

	removeAll() {
		this.data.set('compare_rm_all', true)

		$.ajax({
			method: 'POST',
			processData: false,
			contentType: false,
			cache: false,
			url: '/productvergelijker',
			data: this.data,
		})
			.done(this.handleSuccessRmAll.bind(this))
			.fail(this.handleFailure.bind(this))

		this.data.delete('compare_rm_all')
	}

	toggleCompare() {
		this.data.set('compare_add', true)

		$.ajax({
			method: 'POST',
			processData: false,
			contentType: false,
			cache: false,
			url: '/productvergelijker',
			data: this.data,
		})
			.done(this.handleSuccess.bind(this))
			.fail(this.handleFailure.bind(this))

		this.data.delete('compare_add')
	}

	handleFailure(error) {
		$(document).trigger('message:error', error.message)
	}

	handleSuccess(json) {
		if (typeof json.error === 'string') {
			$(document).trigger('message:error', json.error)
		}
		if (typeof json.message === 'string') {
			this.removeCarousel()
			$(document).trigger('message:success', json.message)
		}

		$(document).trigger('product:compare-count', json.count)
	}

	handleSuccessRmAll(json) {
		if (typeof json.error === 'string') {
			$(document).trigger('message:error', json.error)
		}
		if (typeof json.message === 'string') {
			$(document).trigger('message:success', json.message)
		}

		$(document).trigger('product:compare-count', json.count)
		$(document).trigger('compare:rm-all')
	}

	removeCarousel() {
		const carousel = this.$el.parent().closest('.carousel__slide')
		if (carousel) {
			carousel.remove()
		}
	}

	updateCount(count) {
		this.count.text(count)
	}
}
