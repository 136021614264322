import $ from 'jquery'

import Block from '../block'

export default class Tooltip extends Block {
	static title = 'tooltip'

	constructor($el) {
		super($el)

		this.tooltipMessage = $('.tooltip-message')
		this.tooltipPopup = $('.tooltip-popup')
		this.location = this.$el.offset()

		if (this.$el.data('type') == 'tooltip') {
			this.$el.on('mouseover touchstart', (event) => {
				event.preventDefault()
				this.tooltipMessage.removeClass('tooltip-message--hidden')

				// if contains image, add extra html
				if (this.$el.data('image')) {
					this.tooltipMessage.html(
						`<img class="tooltip-message--image" src="/img/thumb/thumb.php?src=/img/stepoption/${this.$el.data(
							'image',
						)}"/>` + this.$el.data('content'),
					)
				} else {
					this.tooltipMessage.html(this.$el.data('content'))
				}

				// take placement on screen and parent padding into consideration
				let elementLocation = this.getLocation()

				// add extra height on mobile event
				if (event.type == 'touchstart') {
					elementLocation.locationTop =
						elementLocation.locationTop + this.$el.parent().height() + 8
				} else if (this.$el.hasModifier('option')) {
					elementLocation.locationTop =
						elementLocation.locationTop + this.$el.parent().height()
				}

				// change location of tooltip content
				this.tooltipMessage.offset({
					top: elementLocation.locationTop,
					left: elementLocation.locationLeft,
				})
			})

			this.$el.on('mouseleave touchmove touchcancel', () => {
				this.tooltipMessage.addClass('tooltip-message--hidden')
			})
		}

		if (this.$el.data('type') == 'popup') {
			this.$el.on('click', (event) => {
				event.preventDefault()
				this.tooltipPopup.removeClass('tooltip-popup--hidden')

				// if contains image, add extra html
				if (this.$el.data('image')) {
					this.tooltipPopup
						.find('.tooltip-popup__content')
						.html(
							`<img class="tooltip-popup--image" src="/img/thumb/thumb.php?src=/img/stepoption/${this.$el.data(
								'image',
							)}"/>` + this.$el.data('content'),
						)
				} else {
					this.tooltipPopup
						.find('.tooltip-popup__content')
						.html(this.$el.data('content'))
				}
			})

			this.tooltipPopup.on('click', () => {
				this.tooltipPopup.addClass('tooltip-popup--hidden')
			})
		}
	}

	getLocation() {
		this.location = this.$el.offset()
		let locationTop
		let locationLeft
		let fullElementWidth

		// add parent padding
		if (this.$el.data('useParent')) {
			locationTop = this.location.top + this.$el.parent().outerHeight()
		} else {
			locationTop = this.location.top + 8
		}
		locationLeft = this.location.left
		fullElementWidth = locationLeft + 64 + this.tooltipMessage.width()

		// set height check value based on screen position, instead of document position
		const heightCheck =
			this.$el.get(0).getBoundingClientRect().top +
			this.tooltipMessage.outerHeight()

		if (fullElementWidth >= $(window).width()) {
			const overflowX = fullElementWidth - $(window).width()
			locationLeft -= overflowX
		}

		// check if not outside screen
		if (heightCheck + this.tooltipMessage.height() + 32 >= $(window).height()) {
			if (this.$el.data('useParent')) {
				locationTop -=
					this.tooltipMessage.height() +
					this.$el.outerHeight() +
					this.$el.parent().outerHeight() +
					this.$el.height() +
					8

				return { locationTop: locationTop, locationLeft: locationLeft }
			} else {
				locationTop -=
					this.tooltipMessage.height() +
					this.$el.outerHeight() +
					this.$el.height() +
					8

				return { locationTop: locationTop, locationLeft: locationLeft }
			}
		}

		return { locationTop: locationTop, locationLeft: locationLeft }
	}
}
