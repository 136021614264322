import Block from '../../common.blocks/block'
import $ from 'jquery'

export default class Options extends Block {
	static title = 'options'

	constructor($el) {
		super($el)

		this.options = this.$el.findElement('input')
		this.relatedMessage = $('.product-info__message')

		this.options.each((index, el) => {
			if ($(el).is(':checked')) {
				$(document).trigger('option:changed', {
					status: 'add',
					price: Number($(el).data('price')),
				})
			}

			$(el).on('change', (event) => {
				if ($(el).is(':checked')) {
					this.handleSubmit(event, 'add', el)
				} else {
					this.handleSubmit(event, 'remove', el)
				}
			})
		})
	}

	handleSubmit(event, status, el) {
		event.preventDefault()

		this.data = new FormData()
		this.data.append('ajax', true)
		this.data.append('product_id', event.target.value)
		this.data.append('color_id', $(el).data('colorId'))
		this.data.append('size_id', $(el).data('sizeId'))
		this.data.append('related_product_id', $(el).data('relatedProductId'))
		this.data.append('status', status)

		$.ajax({
			method: 'POST',
			processData: false,
			contentType: false,
			cache: false,
			url: window.url,
			data: this.data,
		})
			.done((json) => {
				// check for errors
				if (!json.error) {
					if (json.count > 0) {
						if (this.relatedMessage.length > 0) {
							this.relatedMessage.removeModifier('hidden')
						}
					} else {
						if (this.relatedMessage.length > 0) {
							this.relatedMessage.addModifier('hidden')
						}
					}
					$(document).trigger('option:change')
				}
			})
			.fail((error) => {
				$(document).trigger('message:error', error.message)
			})
	}
}
