import Block from '../../common.blocks/block'
import $ from 'jquery'

export default class Advice extends Block {
	static title = 'advice'

	constructor($el) {
		super($el)

		this.content = this.$el.findElement('content')
		this.link = this.$el.findElement('link')

		$(document).on('advice:formChange', (event, data) => {
			this.changeTab(data)
		})

		this.link.on('click', () => {
			this.changeTab('first')
			$('.form-options__input[value="first"]').prop('checked', true)
		})
	}

	changeTab(data) {
		this.content.each((index, el) => {
			if ($(el).data('tab') == data) {
				$(el).removeClass('advice__content--hidden')
			} else {
				$(el).addClass('advice__content--hidden')
			}
		})
	}
}
