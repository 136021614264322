import Block from '../../common.blocks/block'
import $ from 'jquery'

export default class FormOptions extends Block {
	static title = 'form-options'

	constructor($el) {
		super($el)

		this.options = this.$el.findElement('input')
		this.options.each((index, el) => {
			$(el).on('change', () => {
				$(document).trigger('advice:formChange', $(el).val())
			})
		})
	}
}
