import $ from 'jquery'

import Block from '../block'

export default class Color extends Block {
	static title = 'color'

	constructor($el) {
		super($el)

		this.$el.findElement('input').change((event) => this.emitColorChange(event))

		// send the initial color
		if (this.$el.findElement('input').is(':checked')) {
			// FIXME there should be a more gracious way then to wait 100ms to ensure the product info block is initialized
			window.setTimeout(() => {
				this.emitColorChange()
			}, 100)
		}
	}

	emitColorChange(event) {
		$(document).trigger('color:change', {
			id: this.$el.findElement('input').val(),
			name: this.$el.data('title'),
			remove: event ? !event.target.checked : null,
		})
	}
}
