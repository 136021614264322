import $ from 'jquery'

import Block from '../block'

export default class ShoppingcartPayment extends Block {
	static title = 'shoppingcart-payment'

	constructor($el) {
		super($el)

		this.inputs = this.$el.find('input[name="method_id"]')
		this.inputs.each((index, el) => {
			$(el).on('change', () => {
				$(document).trigger('checkout:add-payment-info', {
					payment_type: $(el).data('paymentTitle'),
				})
			})
		})

		this.$el.find('form').on('submit', () => {
			$(document).trigger('checkout:begin-checkout')
		})
	}
}
