import Block from '../block'

export default class YouTube extends Block {
	static title = 'youtube'

	getDependency() {
		return '//www.youtube.com/iframe_api'
	}

	getGlobalHandler() {
		return ['onYouTubeIframeAPIReady', () => this.initializeYoutubePlayer()]
	}

	initializeYoutubePlayer() {
		this.player = new window.YT.Player(this.$el.get(0), {
			height: '390',
			width: '640',
			host: 'http://www.youtube.com',
			videoId: this.$el.data('videoId'),
			playerVars: {
				autoplay: this.$el.data('autoplay'),
				controls: 0,
				autohide: 1,
				wmode: 'opaque',
				origin: window.location.href,
			},
			events: {
				onReady: (event) => this.ready(event),
				onStateChange: () => this.onPlayerStateChange(),
			},
		})
	}

	ready() {
		if (this.$el.data('mute')) {
			this.player.mute()
		}
	}

	onPlayerStateChange(event) {
		if (event.data === window.YT.PlayerState.ENDED && this.$el.data('loop')) {
			this.player.seekTo(0)
			this.player.playVideo()
		}
	}
}
