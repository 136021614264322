import $ from 'jquery'

import Block from '../../common.blocks/block'
import convertCurrency from '../../scripts/convert-currency'

export default class Steps extends Block {
	static title = 'steps'

	constructor($el) {
		super($el)

		this.dataType = 'product'
		this.data = new FormData(this.$el.get(0))
		this.data.append('ajax', true)
		this.data.set('product_id', $('.steps__container').attr('data-product-id'))

		this.page = 0
		this.configurator = this.$el
		this.steps = this.$el.find('.steps__center')

		this.stepsSummaryOption = this.$el.find('.steps-summary__option')
		this.stepsSummaryPrice = this.$el.find('.steps-summary__price')
		this.stepsSummaryLink = this.$el.findElement('summary-link')
		this.stepsImage = this.$el.find('.steps__container .media__image')

		this.stepsOpenSummary = this.$el.findElement('open-summary')
		this.stepsLeft = this.$el.findElement('left')
		this.stepsOpenSummary.on('click', () => {
			this.stepsOpenSummary.toggleModifier('active')
			this.stepsLeft.toggleModifier('active')
		})

		this.max = this.steps.length - 1
		this.buttons = this.$el.find('.steps__buttons .button')
		this.next = $('.steps__buttons .button--next')
		this.prev = this.$el.find('.steps__buttons .button--prev')
		this.addCart = this.$el.find('.steps__total .add-to-cart')
		this.addInvoice = $('.add-to-invoice')
		this.invoice = $('.invoice')
		this.invoiceShipping = $('input[name=shipping]')
		this.invoiceBackButton = $('.invoice_back')
		this.invoiceSubmit = $('.invoice_submit')
		this.topbar = this.$el.find('.steps__top-step')
		this.sidebar = this.$el.find('.steps__summary-center .steps__selected')
		this.colorPrice = this.$el.find('[name=color_id]').attr('data-color-price')
		this.sizePrice = this.$el.find('[name=size_radio]').attr('data-size-price')
		$(this.sidebar[1])
			.find('.steps-option__price')
			.text(convertCurrency(this.colorPrice))
		$(this.sidebar[2])
			.find('.steps-option__price')
			.text(convertCurrency(this.sizePrice))

		this.hideConfigurator = $('.steps__close-heading')
		this.showConfigurator = $('.button--show-configurator')
		this.loading = false

		this.$el
			.find('input[name="size_radio"]')
			.on('change', () => this.handleSizeChange())

		//inital size_id set
		if (this.$el.find('input[name="size_radio"]').is(':checked')) {
			// FIXME there should be a more gracious way then to wait 100ms to ensure the product info block is initialized
			window.setTimeout(() => {
				this.handleSizeChange()
			}, 100)
		}

		$(this.invoiceShipping).on('change', () => {
			if ($('input[name=shipping]:checked').val() == 1) {
				$('.invoice_shipping').removeModifier('hidden')
			} else {
				$('.invoice_shipping').addModifier('hidden')
			}
		})

		this.$el
			.find('input[name="color_id"]')
			.change(() => this.handleColorChange())

		//inital color_id set
		if (this.$el.find('input[name="color_id"]').is(':checked')) {
			// FIXME there should be a more gracious way then to wait 100ms to ensure the product info block is initialized
			window.setTimeout(() => {
				this.handleColorChange()
			}, 100)
		}

		this.buttons.each((index, el) => {
			$(el).on('click', () => {
				if (this.page >= 0 && this.page <= this.max) {
					const dataStep = $(this.steps[this.page])
						.find('.steps__options')
						.attr('data-step')

					this.changeData(dataStep)

					this.changeStep(dataStep, el)
				}
			})
		})

		$(this.addInvoice).on('click', () => {
			this.configurator.addModifier('hidden')
			this.invoice.removeModifier('hidden')
		})

		$(this.invoiceSubmit).on('click', () => {
			if ($('.invoice input[name="agree"]').prop('checked')) {
				this.handleInvoiceSubmit()
			} else {
				$(document).trigger('message:error')
			}
		})

		$(this.invoiceBackButton).on('click', () => {
			this.configurator.removeModifier('hidden')
			this.invoice.addModifier('hidden')
		})

		$(this.showConfigurator).on('click', () => {
			this.data.set('color_id', $('[name="color_id"]:checked').val())
			this.data.set('size_id', $('[name="size_radio"]:checked').val())
			this.checkRequiredValues()
			$(this.configurator).removeModifier('hidden')
		})

		$(this.hideConfigurator).on('click', () => {
			$(this.configurator).addModifier('hidden')
		})

		$('.steps__open-options').each((index, el) => {
			$(el).on('click', () => {
				$(el)
					.parent()
					.find('label.steps-option--hidden')
					.removeModifier('hidden')
				$(el).parent().find('.steps__open-options').hide()
			})
		})

		$(this.addCart).on('click', () => {
			this.handleCartSubmit()
		})

		this.sidebar.each((index, el) => {
			$(el).on('click', () => {
				const dataStep = $(this.steps[this.page])
					.find('.steps__options')
					.attr('data-step')

				this.changeData(dataStep)

				this.changeStep(dataStep, index)
			})
		})

		this.stepsSummaryLink.each((index, el) => {
			$(el).on('click', () => {
				const dataStep = $(this.steps[this.page])
					.find('.steps__options')
					.attr('data-step')
				//Plus 1 for intro step
				this.changeStep(dataStep, index + 1)
			})
		})

		$('.steps-option input').each((index, el) => {
			$(el).on('click', () => {
				if ($(el).attr('name') == 'color_id') {
					this.handleColorChange()
				} else if ($(el).attr('name') == 'size_radio') {
					this.handleSizeChange()
				}
				this.getSummaryStepText()
			})
		})
	}

	handleSizeChange(event, data) {
		this.data.set('size_id', $('[name="size_radio"]:checked').val())
		$('form.invoice [name="size_id"]').val(
			$('[name="size_radio"]:checked').val(),
		)
		if (data) {
			if (data.stock > 0 || data.availability == 'presale') {
				this.showConfigurator.removeModifier('hidden')
			} else {
				this.showConfigurator.addModifier('hidden')
			}
		}

		$('.steps-option').prop('checked', false)

		$('.steps__summary-top').attr(
			'data-base-price',
			$('[name="size_radio"]:checked').data('price'),
		)

		// $('.steps__total-price-msrp, .steps__total-price').text(
		// 	$('.product-info__price__highlight').text(),
		// )
	}

	handleColorChange() {
		this.$el.find(`.steps__options-size`).addModifier('hidden')

		const color_val = $('[name="color_id"]:checked').val()
		this.data.set('color_id', color_val)
		$('form.invoice [name="color_id"]').val(color_val)

		this.$el
			.find(`.steps__options-size[data-color-id="${color_val}"]`)
			.removeModifier('hidden')

		this.$el
			.find(
				`.steps__options-size[data-color-id="${color_val}"] .steps-option:first-child .steps-option__input`,
			)
			.prop('checked', true)

		if (this.stepsImage.length > 1) {
			this.stepsImage.addModifier('hidden')
			this.$el
				.find(
					`.steps__container .media__image[data-color-id='${this.$el
						.find('input[name="color_id"]:checked')
						.val()}']`,
				)
				.removeModifier('hidden')
		}
	}

	checkRequiredValues() {
		if (this.data.get('color_id') == '' || this.data.get('size_id') == '') {
			$(document).trigger('message:error', 'Deze stap is vereist')
			return false
		}

		return true
	}

	getStepCheckedOptions() {
		return $(this.steps[this.page]).find('.steps__options input:checked')
	}

	getAllCheckedOptions() {
		return $(this.steps).find('.steps__options input:checked')
	}

	getStepCheckeOptionsNames() {
		//Get all option names of current step for sidebar/summary
		return this.getStepCheckedOptions()
			.map(function () {
				return $(this).parent().find('.steps-option__name').text()
			})
			.toArray()
	}

	getStepCheckeOptionsPrices() {
		//Get all Option MSRP prices at once
		return this.getStepCheckedOptions()
			.map(function () {
				return $(this).parent().data('price')
			})
			.toArray()
	}

	getAllCheckeOptionsPrices() {
		//Get all Option prices at once
		return this.getAllCheckedOptions()
			.map(function () {
				return $(this).parent().data('price')
			})
			.toArray()
	}

	getAllCheckeOptionsMsrpPrices() {
		//Get all Option MSRP prices at once
		return this.getAllCheckedOptions()
			.map(function () {
				return $(this).parent().data('price-msrp') > 0
					? $(this).parent().data('price-msrp')
					: $(this).parent().data('price')
			})
			.toArray()
	}

	getCheckedOptionsValues() {
		//Get all checked value of current step en put in array
		const values = []
		$(this.steps[this.page])
			.find('.steps__options input:checked')
			.each(function () {
				values.push($(this).val())
			})
		return values
	}

	getSummaryStepText() {
		//Gets all the data and put it inside an array
		//To change per step in sidebar/summary
		this.optionName = this.getStepCheckeOptionsNames().join('<br>')
		//To change per step in sidebar/summary
		this.optionPrice = this.getStepCheckeOptionsPrices()
		//To change the sidebar/summary final price
		this.totalOptionPrice = this.getAllCheckeOptionsPrices()
		//To change the sidebar/summary MSRP final price
		this.totalOptionPriceMsrp = this.getAllCheckeOptionsMsrpPrices()
		//Calculates all values from array to 1 number
		const optionPrice = this.optionPrice
			.reduce((sum, price) => sum + Number(price), 0)
			.toFixed(2)

		const totalOptionPrice = this.totalOptionPrice.reduce(
			(sum, price) => sum + Number(price),
			0,
		)

		const totalOptionPriceMsrp = this.totalOptionPriceMsrp.reduce(
			(sum, price) => sum + Number(price),
			0,
		)

		//Adding base price of product to prices of all options
		const totalPrice = (
			Number($('.steps__summary-top').attr('data-base-price')) +
			totalOptionPrice
		).toFixed(2)

		//Adding base price of product to MSRP prices of all options
		const totalPriceMsrp = (
			Number($('.steps__summary-top').attr('data-base-price')) +
			totalOptionPriceMsrp
		).toFixed(2)

		//Calculates savings for display
		let totalSavings = (Number(totalPriceMsrp) - Number(totalPrice)).toFixed(2)
		this.changeSummaryStep(optionPrice)

		this.changeSidebarStep(
			optionPrice,
			totalPrice,
			totalPriceMsrp,
			totalSavings,
		)
	}

	changeData(dataStep) {
		this.data.delete(dataStep)

		const values = this.getCheckedOptionsValues()

		for (let i = 0; i < values.length; i++) {
			this.data.append(dataStep, values[i])
		}

		this.getSummaryStepText()
	}

	changeStep(dataStep, el) {
		$(this.steps[this.page]).addModifier('hidden')
		$(this.sidebar[this.page]).removeModifier('current')
		this.data.has(dataStep)
			? $(this.sidebar[this.page]).addModifier('checked')
			: $(this.sidebar[this.page]).removeModifier('checked')

		//This is for the sidebar/summary click navigation
		//Checks if clickable link to page or prev/next button
		if (Number.isInteger(el)) {
			this.page = el
		} else if ($(el).hasModifier('prev') || $(el).hasModifier('next')) {
			if ($(el).hasModifier('prev')) {
				this.page = this.page - 1
			} else {
				this.page = this.page + 1
			}
		}

		//For top bar percentage
		// - 1 for the summary
		const percentage =
			(
				(100 / ($('.steps__selected').length - 2)) *
				$('.steps__selected--checked').length
			).toFixed(0) + '%'

		//Changes text in the top bar
		const top_text = $(this.steps[this.page])
			.find('.steps__options')
			.attr('data-parent')
			? $(this.steps[this.page]).find('.steps__options').attr('data-parent')
			: this.page < 1
			? 'Introductie'
			: 'Samenvatting'

		$(this.topbar).text('Stap ' + (this.page + 1) + ' | ' + top_text)

		$(this.steps[this.page]).removeModifier('hidden')
		$(this.sidebar[this.page]).addModifier('current')
		$('.steps__progress strong').text(percentage + ' voltooid')
		$('.steps__progress-bar span').width(percentage)

		this.checkDisableButton()
	}

	checkDisableButton() {
		this.page > 0
			? this.prev.removeModifier('disabled')
			: this.prev.addModifier('disabled')

		this.page < this.max
			? this.next.removeModifier('disabled')
			: this.next.addModifier('disabled')
	}

	changeSidebarStep(optionPrice, totalPrice, totalPriceMsrp, totalSavings) {
		$(this.sidebar[this.page])
			.find('.steps-option__choice')
			.html(this.optionName)

		$(this.sidebar[this.page])
			.find('.steps-option__price')
			.text(convertCurrency(optionPrice))

		//Summary of price in sidebar
		$(this.sidebar[this.sidebar.length - 1])
			.find('.steps-option__price')
			.text(convertCurrency(totalPrice))
		// This is for sidebar & summary
		$('.steps__total-price').text(convertCurrency(totalPrice))
		if (totalSavings > 0) {
			$('.steps__summary-total strong').text(convertCurrency(totalSavings))
			$('.steps__total-price-msrp').text(convertCurrency(totalPriceMsrp))
			$('.steps__total-price-msrp').removeModifier('hidden')
			$('.steps__summary-total').removeModifier('hidden')
		} else {
			$('.steps__total-price-msrp').addModifier('hidden')
			$('.steps__summary-total').addModifier('hidden')
		}
	}

	changeSummaryStep(optionPrice) {
		$(this.stepsSummaryOption[this.page - 1]).html(this.optionName)

		$(this.stepsSummaryPrice[this.page - 1]).text(convertCurrency(optionPrice))
	}

	getAllInvoiceFormData() {
		const invoiceForm = $('form[name="invoice"]').serializeArray()
		$(invoiceForm).each((key, input) => {
			this.data.set(input.name, input.value)
		})
	}

	handleCartSubmit() {
		if (this.loading) {
			return
		}
		this.setLoading(true)

		this.data.set('add', 1)

		$.ajax({
			method: 'POST',
			processData: false,
			contentType: false,
			cache: false,
			url: '/shoppingcart',
			data: this.data,
		})
			.done((json) => {
				// check for errors
				if (!json.error) {
					$(document).trigger('product:add', json)
					$(document).trigger('shoppingcart:add-to-cart', this.dataType)
				}
			})
			.fail((error) => {
				$(document).trigger('message:error', error.message)
			})
			.always(() => this.setLoading(false))
	}

	handleInvoiceSubmit() {
		if (this.loading) {
			return
		}
		this.setLoading(true)
		const btn = $('.invoice_submit')
		btn.addModifier('loading').addModifier('disabled')

		this.data.set('add', 1)
		this.data.set('shipping_id', 11)
		this.data.set('payment_id', 17)
		this.getAllInvoiceFormData()
		$.ajax({
			method: 'POST',
			processData: false,
			contentType: false,
			cache: false,
			url: '/invoice',
			data: this.data,
		})
			.done((json) => {
				// check for errors
				if (!json.error) {
					window.location.href = '/bedankt-pagina-offerte'
				}
			})
			.fail((error) => {
				$(document).trigger('message:error', error.message)
			})
			.always(() => {
				this.setLoading(false)
				btn.removeModifier('loading').removeModifier('disabled')
			})
	}

	setLoading(loading) {
		this.loading = loading
	}
}
