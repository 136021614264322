import Block from '../block'
import Swiper, { Autoplay, Navigation, Pagination } from 'swiper'

export default class Carousel extends Block {
	static title = 'carousel'

	constructor($el) {
		super($el)

		if (this.$el.data('autoplay')) {
			Swiper.use(Autoplay)
		}

		this.swiper = new Swiper(this.$el.get(0), {
			autoplay: this.$el.data('autoplay'),

			wrapperClass: 'carousel__slides',
			slideClass: 'carousel__slide',

			modules: [Navigation, Pagination],

			speed: 300,
			shortSwipes: false,

			loop: this.$el.data('loop'),

			navigation: {
				nextEl: this.$el.findElement('next').get(0),
				prevEl: this.$el.findElement('prev').get(0),
			},

			pagination: {
				el: this.$el.findElement('progress').get(0),
				type: 'bullets',
				bulletClass: 'carousel__bullet',
				bulletActiveClass: 'carousel__bullet--active',
				clickable: true,
			},

			slidesPerView: this.$el.data('items') ?? 1,
			spaceBetween: 0,

			breakpoints: {
				0: {
					slidesPerView:
						this.$el.data('items-mobile') || this.$el.data('items'),
				},
				568: {
					slidesPerView:
						this.$el.data('items-mobile-landscape') || this.$el.data('items'),
				},
				768: {
					slidesPerView:
						this.$el.data('items-tablet') || this.$el.data('items'),
				},
				960: {
					slidesPerView:
						this.$el.data('items-tablet-landscape') || this.$el.data('items'),
				},
				1140: {
					slidesPerView:
						this.$el.data('items-desktop') || this.$el.data('items'),
				},
				1440: {
					slidesPerView:
						this.$el.data('items-desktop-hd') || this.$el.data('items'),
				},
			},

			on: {
				afterInit: () => {
					this.$el.addModifier('initialized')
				},
			},
		})

		$(document).on('product:compare-count', () => this.swiper.update())
	}
}
