import $ from 'jquery'
import Block from '../../common.blocks/block'

export default class StickyAtb extends Block {
	static title = 'sticky-atb'

	constructor($el) {
		super($el)

		this.button = this.$el.findElement('button')
		if ($('button.button--add-to-cart').length > 0) {
			this.height =
				$('button.button--add-to-cart').position().top +
				$('button.button--add-to-cart').outerHeight(true)
		}

		this.button.on('click', () => {
			$(document).trigger('sticky:addToCart')
		})

		$(document).on('scroll', () => {
			let y = $(document).scrollTop()
			if (y > this.height) {
				this.$el.removeModifier('hidden')
			} else {
				this.$el.addModifier('hidden')
			}
		})
	}
}
