import $ from 'jquery'
import Block from '../../common.blocks/block'

export default class Set extends Block {
	static title = 'set'

	constructor($el) {
		super($el)

		this.form = this.$el.findElement('form')

		this.form.on('submit', (e) => this.handleSubmit(e))
	}

	handleSubmit(event) {
		event.preventDefault()

		this.data = new FormData()
		this.data.append('ajax', true)
		this.data.append('count', 1)
		this.data.append('add_set', true)
		this.data.append('set_id', this.form.find('input[name="set_id"]').val())

		// set is required
		if (this.data.has('set_id') === false) {
			$(document).trigger('message:error', 'Something went wrong')
			return null
		}

		this.setLoading(true, this.$el)

		$.ajax({
			method: 'POST',
			processData: false,
			contentType: false,
			cache: false,
			url: '/shoppingcart',
			data: this.data,
		})
			.done((json) => {
				// check for errors
				if (!json.error) {
					$(document).trigger('product:add', json)
					$(document).trigger('shoppingcart:add-to-cart', this.dataType)
				}
			})
			.fail((error) => {
				$(document).trigger('message:error', error.message)
			})
			.always(() => this.setLoading(false, this.$el))
	}

	setLoading(loading, element) {
		if (loading) {
			$(element).find('.button').addModifier('loading')
		} else {
			$(element).find('.button').removeModifier('loading')
		}
	}
}
