import Block from '../block'

export default class Social extends Block {
	static title = 'social'

	constructor($el) {
		super($el)

		const self = this
		this.$el.find('.social__icon[data-service]').click(function () {
			const url = self.getShareUrl($(this).data('service'))

			window.open(url, 'Share', 'height=400,width=600').focus()
		})
	}

	getShareUrl(service) {
		switch (service) {
			case 'facebook':
				return `https://www.facebook.com/sharer.php?u=${encodeURIComponent(
					this.$el.data('url'),
				)}&display=popup`

			case 'linkedin':
				return `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
					this.$el.data('url'),
				)}&source=LinkedIn`

			case 'twitter': {
				let url = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
					this.$el.data('url'),
				)}`
				if (this.$el.data('title')) {
					url += `&text=${encodeURIComponent(this.$el.data('title'))}`
				}

				return url
			}

			default:
				if (process.env.NODE_ENV === 'development') {
					// eslint-disable-next-line no-console
					console.error('invalid share service provided')
				}
		}
	}
}
