import $ from 'jquery'

import Block from '../../common.blocks/block'

export default class AccountTable extends Block {
	static title = 'account-table'

	constructor($el) {
		super($el)

		this.form = this.$el.findElement('form')

		this.form.on('submit', (e) => {
			e.preventDefault()
			this.setLoading(true, e.currentTarget)

			const data = new FormData()
			data.append('add', true)
			data.append(
				'product_id',
				$(e.currentTarget).find('input[name="product_id"]').val(),
			)
			data.append(
				'option_id',
				$(e.currentTarget).find('input[name="option_id"]').val(),
			)
			data.append('count', $(e.currentTarget).find('input[name="count"]').val())
			data.append('ajax', true)

			$.ajax({
				method: 'POST',
				processData: false,
				contentType: false,
				cache: false,
				url: this.form.attr('action'),
				data: data,
			})
				.done((json) => {
					// check for errors
					if (!json.error) {
						$(document).trigger('product:add', json)
						$(document).trigger('shoppingcart:add-to-cart', this.dataType)
					}
				})
				.fail((error) => {
					$(document).trigger('message:error', error.message)
				})
				.always(() => this.setLoading(false, e.currentTarget))
		})
	}

	setLoading(loading, target) {
		if (loading) {
			$(target).find('.button').addModifier('loading')
		} else {
			$(target).find('.button').removeModifier('loading')
		}
	}
}
