import Block from '../block'

export default class GoogleMap extends Block {
	static title = 'google-map'

	getDependency() {
		return `https://maps.googleapis.com/maps/api/js?key=${this.$el.data(
			'api-key',
		)}&libraries=geometry`
	}

	load() {
		// instantiate the Google Maps object
		this.map = new google.maps.Map(this.$el.findElement('map').get(0), {
			center: {
				lat: parseFloat(this.$el.data('latitude')),
				lng: parseFloat(this.$el.data('longitude')),
			},
			zoom: parseInt(this.$el.data('zoom'), 10) || 6,
		})

		if (this.$el.data('marker-label')) {
			const marker = this.createMarker(
				{
					lat: parseFloat(this.$el.data('latitude')),
					lng: parseFloat(this.$el.data('longitude')),
				},
				'center',
				this.$el.data('marker-label'),
				this.$el.data('marker-label'),
			)

			this.markers.push(marker)
		}
	}

	createMarker(position, id, title, infoWindowContent) {
		const infoWindow = infoWindowContent
			? new google.maps.InfoWindow({
					content: infoWindowContent,
			  })
			: null

		const marker = new google.maps.Marker({
			map: this.map,
			position,
			id,
			title,
		})

		marker.addListener('click', () => {
			if (this.map.getZoom() < 10) {
				this.map.setZoom(10)
			}

			this.map.setCenter(marker.getPosition())

			if (infoWindow) {
				infoWindow.open(this.map, marker)
			}
		})

		return marker
	}

	panToLocationByGeolocator() {
		// get the GPS location
		navigator.geolocation.getCurrentPosition(
			(position) => {
				this.map.setCenter({
					lat: position.coords.latitude,
					lng: position.coords.longitude,
				})
				this.map.setZoom(10)
			},
			// no location received, show an error
			(error) => {
				$(document).trigger('message:error', error.message)
			},
		)
	}

	// get the location from the user's input
	panToLocationByQuery(query) {
		return new Promise((resolve) => {
			// request latlng from user's input
			const geocoder = new google.maps.Geocoder()
			geocoder.geocode({ address: query }, (results, status) => {
				if (status === google.maps.GeocoderStatus.OK && results.length > 0) {
					this.map.setCenter({
						lat: results[0].geometry.location.lat(),
						lng: results[0].geometry.location.lng(),
					})

					this.map.setZoom(10)

					resolve({
						lat: results[0].geometry.location.lat(),
						lng: results[0].geometry.location.lng(),
					})
				}
			})
		})
	}

	navigateToMarker($el) {
		this.map.setCenter({
			lat: $el.data('latitude'),
			lng: $el.data('longitude'),
		})
		this.map.setZoom(10)
	}
}
