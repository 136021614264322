import $ from 'jquery'

import CommonPopup from '../../common.blocks/popup'

export default class Popup extends CommonPopup {
	static title = 'popup'

	constructor($el) {
		super($el)

		const trigger = this.$el.data('triggerElement')
		if (trigger) {
			$(trigger).on('click', () => this.open())
		}

		$(document).on('overlay:close', () => {
			this.$el.addModifier('hidden')
		})
	}
}
