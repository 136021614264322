import $ from 'jquery'
import Block from '../../common.blocks/block'

export default class ChoicesSelect extends Block {
	static title = 'choices-select'

	constructor($el) {
		super($el)

		$el.findElement('select').each((_, el) => {
			const $select = $(el)

			$(el).on('change', (event) => {
				const $parent = $select.parent(`.${ChoicesSelect.title}`)
				const $next = $parent.find(
					`.${ChoicesSelect.title}[data-id="${event.target.value}"]`,
				)

				$parent
					.find(`.${ChoicesSelect.title}`)
					.each((_, el) =>
						$(el).addModifier('hidden').findElement('select').val(''),
					)
				$next.removeModifier('hidden')
			})
		})
	}
}
