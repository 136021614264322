import Block from '../../common.blocks/block'
import $ from 'jquery'

export default class Sidebar extends Block {
	static title = 'sidebar'

	constructor($el) {
		super($el)

		$(document).on('filter:toggle', () => {
			this.$el.toggleClass('sidebar--hidden')
		})
	}
}
