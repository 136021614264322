import Block from '../block'

export default class Form extends Block {
	static title = 'form'

	constructor($el) {
		super($el)

		if (this.$el.hasModifier('autosubmit')) {
			this.$el
				.find(':input')
				.not('[data-form-manual-control]')
				.change(() => {
					this.$el.submit()
				})
		}

		if (this.$el.hasModifier('specs')) {
			const phoneField = this.$el
				.find('input[name="fields[phone]"]')
				.parent()
				.parent()

			this.approachField = this.$el.find('select[name="fields[approach]"]')
			this.approachField.on('change', () => {
				if (this.approachField.val() == 55) {
					phoneField.removeClass('form__field--hidden')
				} else {
					phoneField.addClass('form__field--hidden')
				}
			})
		}
	}
}
