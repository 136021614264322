import $ from 'jquery'

import Block from '../block'

export default class AccountNav extends Block {
	static title = 'account-nav'

	constructor($el) {
		super($el)

		if (this.$el.hasClass('.account-nav--mobile')) {
			$('.account-nav__toggle').click(() => {
				this.$el.toggle('.account-nav--mobile-active')
			})
		}
	}
}
