import $ from 'jquery'

import Popup from '../../common.blocks/popup'

export default class NotifyPopup extends Popup {
	static title = 'notify-popup'

	constructor($el) {
		super($el)
		this.loading = false
		this.loggedIn = this.$el.data('loggedIn')

		this.$trigger = $(`.${NotifyPopup.title}__trigger`)
		this.$trigger.on('click', () => this.onTriggerClick())
		this.$el.findElement('continue-shopping').on('click', () => this.close())

		this.data = new FormData()
		this.data.set('ajax', true)
		this.data.set('wishlist_add', true)
		this.data.set('product_id', this.$el.data('productId'))
		this.data.set('notify', true)

		$(document).on('color:change', (event, data) => {
			this.data.set('color_id', data.id)
		})
		$(document).on('size:change', (event, data) => {
			this.data.set('size_id', data.id)
		})

		$(document).on('overlay:close', () => this.hide())
	}

	onTriggerClick() {
		if (!this.loggedIn) {
			this.open()
		} else {
			this.notify()
		}
	}

	notify() {
		if (this.loading) {
			return
		} else {
			this.setLoading(true)
		}

		$.ajax({
			method: 'POST',
			processData: false,
			contentType: false,
			cache: false,
			url: '/account',
			data: this.data,
		})
			.done((json) => this.handleSuccess(json))
			.fail(({ responseJSON: json }) => this.handleFailure(json))
			.always(() => this.setLoading(false))
	}

	handleSuccess(json) {
		if (typeof json.message === 'string') {
			$(document).trigger('product:add-to-wishlist')
			$(document).trigger('message:success', json.message)
		}

		if (typeof json.error === 'string') {
			$(document).trigger('message:error', json.error)
		} else {
			this.showSuccess()
			this.open()
		}
	}

	handleFailure(json) {
		$(document).trigger('message:error', json.message)
	}

	showSuccess() {
		this.hideAllContainers()
		this.$el
			.findElement('success-container')
			.removeClass('popup__container--hidden')
	}

	hideAllContainers() {
		this.$el.find(`.popup__container`).addClass('popup__container--hidden')
	}

	reset() {
		this.$el
			.findElement('form-container')
			.removeClass('popup__container--hidden')
		this.$el
			.findElement('success-container')
			.addClass('popup__container--hidden')
	}

	setLoading(loading) {
		const cls = 'button--loading'
		if (loading) {
			this.$trigger.addClass(cls)
		} else {
			this.$trigger.removeClass(cls)
		}
		this.loading = loading
	}

	hide() {
		this.$el.addModifier('hidden')
		this.reset()
	}

	close() {
		super.close()
		this.reset()
	}
}
