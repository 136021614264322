import 'magnific-popup'
import CommonCarousel from '../../common.blocks/carousel'
import $ from 'jquery'

export default class Carousel extends CommonCarousel {
	static title = 'carousel'

	constructor($el) {
		super($el)

		if (this.$el.hasModifier('colors')) {
			this.iframe = this.$el.findElement('video--iframe')

			this.$el.find('.media--product').magnificPopup({
				type: 'image',
				gallery: {
					enabled: true,
					navigationByImageClick: true,
					preload: [0, 1],
				},

				tLoading: `${this.$el.data('loadingLabel')}...`,
			})

			$(document).on('color:change', (event, data) => {
				if (this.$el.data('colorId') == data.id) {
					this.$el.removeModifier('hidden')
					this.iframe.attr('src', this.iframe.data('video'))
				} else {
					this.$el.addModifier('hidden')
					this.iframe.attr('src', '')
				}
			})
		}

		if (this.$el.hasModifier('pdp')) {
			this.$el.find('.media--product').magnificPopup({
				type: 'image',
				gallery: {
					enabled: true,
					navigationByImageClick: true,
					preload: [0, 1],
				},

				tLoading: `${this.$el.data('loadingLabel')}...`,
			})
		}
	}
}
