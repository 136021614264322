import Block from '../../common.blocks/block'

export default class ActiveFilters extends Block {
	static title = 'active-filters'

	constructor($el) {
		super($el)

		this.replacement = this.$el.find('.active-filters__replacement--price')
		this.input = this.$el.find('input[name="filters[price][]"]')
		let values = null

		if (this.input[0]) {
			values = this.input.val().match(/\d+/g)
		}

		if (this.replacement[0] && values) {
			this.replacement.html(
				`€${values[0]} - €${values[1]}<i class="far fa-times"></i>`,
			)
		}
	}
}
