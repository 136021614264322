import Block from '../block'

export default class GoogleRecaptcha extends Block {
	static title = 'google-recaptcha'

	getDependency() {
		return 'https://www.google.com/recaptcha/api.js?render=explicit'
	}

	load() {
		window.grecaptcha.ready(() => {
			window.grecaptcha.render(this.$el.get(0), {
				sitekey: this.$el.data('sitekey'),
			})
		})
	}
}
