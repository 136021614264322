import $ from 'jquery'

import Block from '../block'

export default class Overlay extends Block {
	static title = 'overlay'

	constructor($el) {
		super($el)

		if (this.$el.hasModifier('main')) {
			$(document).on('popup:open', () => {
				this.$el.removeModifier('hidden')
			})

			$(document).on('popup:close', () => {
				this.$el.addModifier('hidden')
				$(document).trigger('overlay:close')
			})

			this.$el.click(() => {
				this.$el.addModifier('hidden')
				$(document).trigger('overlay:close')
			})
		}
	}
}
