import Block from '../../common.blocks/block'

export default class Parcelshops extends Block {
	static title = 'parcelshops'

	constructor($el) {
		super($el)

		this.checkbox = this.$el.findElement('checkbox')
		this.formContainer = this.$el.findElement('form')
		this.form = this.$el.findElement('form-content')
		this.select = this.$el.find('select[name=parcelshop_id]')
		this.loader = this.$el.findElement('loader')
		this.errorMsg = this.$el.findElement('error-msg')

		this.parcelshopZipcodeInput = this.form.find(
			'input[name="parcelshop_zipcode"]',
		)
		this.parcelshopNumberInput = this.form.find(
			'input[name="parcelshop_number"]',
		)

		this.parcelshopNumberInput.focus()
		this.parcelshopNumberInput.blur()
		this.parcelshopZipcodeInput.on('input', () => {
			this.parcelshopNumberInput.val('')
		})

		if (this.checkbox.is(':checked')) {
			this.formContainer.find('input').prop('disabled', false)
			this.formContainer.find('select').prop('disabled', false)
			this.formContainer.removeModifier('hidden')
		} else {
			this.formContainer.find('input').prop('disabled', true)
			this.formContainer.find('select').prop('disabled', true)
			this.formContainer.addModifier('hidden')
		}

		this.checkbox.on('change', () => {
			if (this.checkbox.is(':checked')) {
				this.formContainer.find('input').prop('disabled', false)
				this.formContainer.find('select').prop('disabled', false)
				this.formContainer.removeModifier('hidden')
				this.getParcelshops()
			} else {
				const data = new FormData()
				data.append('remove_parcelshop', 1)
				$.ajax({
					method: 'POST',
					processData: false,
					contentType: false,
					cache: false,
					url: '/parcelshop',
					data: data,
				})
					.done(() => {
						this.formContainer.find('input').prop('disabled', true)
						this.formContainer.find('select').prop('disabled', true)
						this.formContainer.addModifier('hidden')
						window.location.hash = '#shipping'
						window.location.reload()
					})
					.fail(() => {
						// fail silently
					})
			}
		})

		if (this.checkbox.is(':checked') && this.formContainer.is(':visible')) {
			this.getParcelshops()
		}

		this.form.on('submit', (event) => {
			event.preventDefault()

			this.getParcelshops()
		})
	}

	getParcelshops() {
		this.setLoading(true)
		const data = new FormData(this.form.get(0))
		$.ajax({
			method: 'POST',
			processData: false,
			contentType: false,
			cache: false,
			url: '/parcelshop',
			data: data,
		})
			.done((json) => this.handleSuccess(json))
			.fail(() => {
				// fail silently
			})
			.always(() => {
				this.setLoading(false)
			})
	}

	handleSuccess(json) {
		if (json.html) {
			this.select.html(json.html)
		} else {
			this.select.html('')
			this.errorMsg.text(json.error)
		}
	}

	setLoading(bool) {
		if (bool) {
			this.loader.removeModifier('hidden')
			this.errorMsg.text('')
			this.errorMsg.addClass('text--hidden')
		} else {
			this.loader.addModifier('hidden')
			this.errorMsg.removeClass('text--hidden')
		}
	}
}
