import Block from '../../common.blocks/block'
import $ from 'jquery'

export default class MobileFilters extends Block {
	static title = 'mobile-filters'

	constructor($el) {
		super($el)

		this.toggle = this.$el.findElement('toggle')

		this.toggle.on('click', () => this.toggleFilters())
	}

	toggleFilters() {
		$(document).trigger('filter:toggle')
	}
}
