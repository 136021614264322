import $ from 'jquery'

import Block from '../block'

export default class Size extends Block {
	static title = 'size'

	constructor($el) {
		super($el)

		this.$el.findElement('input').change((event) => this.handleChange(event))

		// $(document).on('color:change', (event, data) =>
		// 	this.handleColorChange(event, data),
		// )
	}

	handleChange(event) {
		$(document).trigger('size:change', {
			id: event.target.value,
			name: this.$el.data('title'),
			remove: !event.target.checked,
		})
	}

	// handleColorChange(event, data) {
	// 	if (this.$el.data('colorId').toString() === data.id.toString()) {
	// 		this.$el.removeModifier('hidden')
	// 	} else {
	// 		this.$el.addModifier('hidden')
	// 	}
	// }
}
