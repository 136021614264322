/**
 * Copy of `convertCurrency` (from vars.php) in our PHP layer used in Smarty
 * templates.
 *
 * Converts given `price` in Dutch-style EUR currency format. Examples: `39.990`
 * -> "€ 39,99", `10.00` -> "€ 10,-"
 *
 * Note: It prefixes the currency symbol "€" for you.
 *
 * @param {string|number} price
 * @returns {string}
 */
// Explicit named function declaration used so it's auto-importable by simply
// starting to write the function name in another file instead of being
// ambiguous.
export default function convertCurrency(price) {
	if (typeof price === 'string') {
		let parsedPrice = Number(price)
		// check for possible invalid price that most likely uses "." for thousands
		// and "," for decimal which is incorrect for the JS number type
		if (isNaN(price)) {
			parsedPrice = Number(price.replace('.', '').replace(',', '.'))
		}
		price = parsedPrice
	}

	const currencyFormatter = new Intl.NumberFormat('nl-NL', {
		style: 'currency',
		currency: 'EUR',
		trailingZeroDisplay: 'stripIfInteger',
	})
	const formattedPrice = currencyFormatter.format(price)

	// whole numbers in Dutch currency are displayed with a ",-" suffix
	if (!formattedPrice.includes(',')) {
		return `${formattedPrice},-`
	}

	return formattedPrice
}
