import Block from '../block'

export default class Nav extends Block {
	static title = 'nav'

	constructor($el) {
		super($el)

		this.$el.find('.nav__item[data-shoppingcart]').click((event) => {
			event.preventDefault()
			$(document).trigger('nav:open-shoppingcart')
		})
	}
}
