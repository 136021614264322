import $ from 'jquery'
import 'magnific-popup'
import Block from '../../common.blocks/block'

export default class ExtendedProductImage extends Block {
	static title = 'product-images-extended'

	constructor($el) {
		super($el)

		this.iframe = this.$el.findElement('video--iframe')

		this.$el.findElement('image').magnificPopup({
			type: 'image',
			gallery: {
				enabled: true,
				navigationByImageClick: true,
				preload: [0, 1],
			},

			tLoading: `${this.$el.data('loadingLabel')}...`,
		})

		this.$el.findElement('video').magnificPopup({
			type: 'iframe',
			gallery: {
				enabled: true,
				navigationByImageClick: true,
				preload: [0, 1],
			},

			tLoading: `${this.$el.data('loadingLabel')}...`,
		})
	}

	destroy() {
		$.magnificPopup.close()
	}
}
