import $ from 'jquery'

import CommonProductInfo from '../../common.blocks/product-info'
import convertCurrency from '../../scripts/convert-currency'

export default class ProductInfo extends CommonProductInfo {
	static title = 'product-info'

	constructor($el) {
		super($el)

		this.price = this.$el.findElement('price__highlight')
		this.advice = this.$el.findElement('price__advice')
		this.diff = this.$el.findElement('price__diff')
		this.diffContainer = this.$el.findElement('price__diff--container')
		this.selectedSize = this.$el.findElement('option--size')
		this.selectedColor = this.$el.findElement('option--color')
		this.priceMessage = this.$el.findElement('price--message')

		this.optionsPrices = []
		this.basePrice = 0

		// if an user selects a new color, we want to display the corresponding size select
		$(document).on('size:change', (event, data) =>
			this.handleSizeChange(event, data),
		)

		$(document).on('color:change', (event, data) =>
			this.handleColorChange(event, data),
		)

		if (
			this.priceMessage.data('extended') &&
			this.priceMessage.data('message')
		) {
			this.priceMessageChange()
		}

		$(document).on('option:change', () => {
			this.handleOptionChange()
		})
	}

	handleOptionChange() {
		// related set and related products
		const relatedSet = this.$el.find(
			'.product-options__input[type="radio"]:checked',
		)
		const relatedProducts = this.$el.find(
			'.options__input[type="checkbox"]:checked',
		)

		// base product prices
		let productPrice = parseFloat(
			$('.size-select__input:checked').data('price'),
		)
		let productPriceAdvice = parseFloat(
			$('.size-select__input:checked').data('priceAdvice'),
		)

		// product diff and base price advice calc
		let productPriceDiff = 0
		if (productPriceAdvice > productPrice) {
			productPriceDiff = productPriceAdvice - productPrice
		} else {
			productPriceAdvice = productPrice
		}

		// checked related set
		if (relatedSet && relatedSet.length > 0) {
			const price = parseFloat(relatedSet.data('extraPrice'))
			const discount = parseFloat(relatedSet.data('extraDiscount'))

			productPrice += price
			productPriceAdvice += price + discount
			productPriceDiff += discount
		}

		// checked related products
		if (relatedProducts && relatedProducts.length > 0) {
			relatedProducts.each((index, el) => {
				const price = parseFloat($(el).data('price'))
				const priceAdvice =
					parseFloat($(el).data('priceAdvice')) > 0
						? parseFloat($(el).data('priceAdvice'))
						: parseFloat($(el).data('price'))

				productPrice += price
				productPriceAdvice += priceAdvice
				if (priceAdvice > price) {
					productPriceDiff += priceAdvice - price
				}
			})
		}

		// set product price
		this.price.text(convertCurrency(productPrice))

		// set product price advice or hide
		if (productPriceAdvice > productPrice) {
			this.advice.text(convertCurrency(productPriceAdvice))
			this.advice.removeModifier('hidden')
		} else {
			this.advice.addModifier('hidden')
		}

		// set product price diff or hide
		if (productPriceDiff > 0) {
			this.diff.text(convertCurrency(productPriceDiff))
			this.diffContainer.removeClass(
				'product-info__price__diff--container-hidden',
			)
		} else {
			this.diffContainer.addClass('product-info__price__diff--container-hidden')
		}
	}

	handleSizeChange(event, data) {
		const title = this.selectedSize.data('title')
		this.selectedSize.text(title + ': ' + data.name)

		this.handleOptionChange()

		if (data.stock > 0 || data.availability == 'presale') {
			if (this.priceMessage.data('message')) {
				this.priceMessage.text(this.priceMessage.data('message') + ':')
			} else {
				this.priceMessage.text('Direct te bestellen voor:')
			}
		} else {
			this.priceMessage.text('Leverbaar voor')
		}
	}

	handleColorChange(event, data) {
		const title = this.selectedColor.data('title')
		this.selectedColor.text(title + ': ' + data.name)
	}

	priceMessageChange() {
		this.priceMessage.text(this.priceMessage.data('message') + ':')
	}
}
