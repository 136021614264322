import Block from '../block'

export default class HCaptcha extends Block {
	static title = 'h-captcha'

	getDependency() {
		return 'https://hcaptcha.com/1/api.js?render=explicit'
	}

	load() {
		window.hcaptcha.render(this.$el.get(0), {
			sitekey: this.$el.data('sitekey'),
		})
	}
}
