import GoogleMap from '../google-map'
import debounce from 'lodash.debounce'

const MAX_DISTANCE_TO_STORE = 50000

export default class StoreLocator extends GoogleMap {
	static title = 'storelocator'

	constructor($el) {
		super($el)

		this.stores = []
	}

	createInfoWindowContent($el) {
		const name = $el.findElement('name').html()
		const address = $el.findElement('address').html()
		const info = $el.findElement('info').html()

		return name + '<br />' + address + '<br />' + info
	}

	load() {
		super.load()

		const self = this

		this.$el.findElement('store').each(function () {
			const position = {
				lat: parseFloat($(this).data('latitude')),
				lng: parseFloat($(this).data('longitude')),
			}

			const marker = self.createMarker(
				position,
				$(this).data('marker-id'),
				$(this).findElement('name').text() ?? '',
				self.createInfoWindowContent($(this)),
			)

			self.stores.push({
				$el: $(this),
				marker,
				position,
				distance: 0,
			})
		})

		// go to the geolocation of the visitor
		this.$el.findElement('icon').click(() => {
			this.panToLocationByGeolocator()
		})

		// pan to the store on click
		this.$el.findElement('name').click((event) => {
			this.navigateToMarker($(event.target).parent())
		})

		this.$el.findElement('input').keydown(
			debounce((event) => this.handleQueryChange(event), 350, {
				leading: true,
			}),
		)
	}

	handleQueryChange(event) {
		const key = event.which || event.keyCode

		// reset when an user presses esc or there is no query
		if (key === 27 || event.target.value === '') {
			this.$el.findElement('store').removeModifier('hidden')
			this.map.setZoom(3)
			return null
		}

		this.panToLocationByQuery(event.target.value).then((location) => {
			// calculate the distance, and hide stores that are too far away
			this.stores.map((store) => {
				const distance = google.maps.geometry.spherical.computeDistanceBetween(
					store.position,
					location,
				)

				if (distance > MAX_DISTANCE_TO_STORE) {
					store.$el.addClass('storelocator__store--hidden')
				} else {
					store.$el.removeClass('storelocator__store--hidden')
				}

				return {
					...store,
					distance,
				}
			})

			// sort stores by distance to current user
			this.stores = this.stores.sort((a, b) => {
				return a.distance > b.distance ? 1 : -1
			})

			// append store HTML, sorted by distance
			this.$el.findElement('stores').html('')
			this.stores.forEach((store) => {
				this.$el.findElement('stores').append(store.$el)
			})
		})
	}
}
