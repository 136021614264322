import $ from 'jquery'
import Block from '../../common.blocks/block'

export default class Collapsable extends Block {
	static title = 'collapsable'

	constructor($el) {
		super($el)

		this.cookie = this.$el.data('cookie')
		this.content = this.$el.findElement('content')
		this.container = this.$el.findElement('container')
		this.button = this.$el.find('.collapsable__button')
		this.table = this.container.find('table.table')

		// CSS transitions works only if height is set
		this.container.height(this.container.height())

		if (this.table.get(0)) {
			let totalHeight = 0

			this.table
				.find('tbody')
				.children()
				.each(function (index, el) {
					totalHeight = totalHeight + $(el).outerHeight(true)

					return index < 4
				})

			this.container.height(totalHeight)

			this.button.on('click', (el) => {
				if ($(el.currentTarget).data('open')) {
					this.open(this.table.height())
				}

				if ($(el.currentTarget).data('close')) {
					this.close(totalHeight)
				}
			})
		} else {
			this.button.on('click', (el) => {
				if ($(el.currentTarget).data('open')) {
					this.open()
				} else if ($(el.currentTarget).data('close')) {
					this.close()
				}
			})
		}
	}

	open(height = this.content?.height()) {
		this.container.height(height)
		this.button.toggleModifier('hidden')
		if (this.cookie) {
			this.setCookie(true)
		}
	}

	close(height = 0) {
		this.container.height(height)
		this.button.toggleModifier('hidden')
		if (this.cookie) {
			this.setCookie(false)
		}
	}

	setCookie(open) {
		const data = new FormData()
		data.append('ajax', true)
		data.append('cookie', this.cookie)
		data.append('value', open)
		data.append('cookie_expire', '+30 days')

		this.request?.abort()
		this.request = $.ajax({
			method: 'POST',
			processData: false,
			contentType: false,
			cache: false,
			url: '/cookie',
			data,
		})
	}
}
