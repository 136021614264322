import Block from '../block'

export default class Sorting extends Block {
	static title = 'sorting'

	constructor($el) {
		super($el)

		this.$el.findElement('select').change(function () {
			const [order, sc] = $(this).val().split(',')

			$el.find('[name="order"]').val(order)
			$el.find('[name="sc"]').val(sc)

			$el.submit()
		})
	}
}
