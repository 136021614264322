import $ from 'jquery'

import Buttons from '../../common.blocks/button'

export default class Button extends Buttons {
	static title = 'button'

	constructor($el) {
		super($el)

		if (this.$el.hasModifier('add-to-cart')) {
			$(document).on('product:inStock', (event, data) =>
				this.disableButton(event, data),
			)
		}
	}

	disableButton(event, data) {
		if (data.status) {
			this.$el.prop('disabled', false)
		} else {
			this.$el.prop('disabled', true)
		}
	}
}
