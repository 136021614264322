import $ from 'jquery'

import Block from '../block'

export default class AccountLoyalty extends Block {
	static title = 'account-loyalty'

	constructor($el) {
		super($el)

		this.pointSelect = this.$el.findElement('points')
		this.pointValue = this.$el.findElement('value')
		this.pointPrice = this.$el.findElement('price')
		this.giftcardValue = this.$el.findElement('giftcard').val()

		$(this.pointSelect).on('change', () =>
			this.handleChange(this.pointSelect.val()),
		)
	}

	handleChange(data) {
		this.pointValue.val(data)
		const price = data / this.giftcardValue
		this.pointPrice.val('€ ' + price.toFixed(2))
	}
}
