import $ from 'jquery'

import Popup from '../popup'
import * as blocks from '../'
import run from '../../scripts/run'
import convertCurrency from '../../scripts/convert-currency'

export default class ShoppingcartPopup extends Popup {
	static title = 'shoppingcart-popup'

	constructor($el) {
		super($el)

		$(document).on('product:add', (event, data) => this.render(data))
		$(document).on('nav:open-shoppingcart', () => this.viewCart())
		$(document).on('overlay:close', () => {
			this.$el.addModifier('hidden')
		})

		this.$el.findElement('back-button').on('click', () => {
			super.close()
		})

		this.$el.on('change', 'form', (event) => this.handleFormChange(event))
	}

	render(data) {
		super.open()

		this.$el.removeModifier('hidden')

		this.$el.findElement('product-count').text(data.count)
		if (parseFloat(data.promotion) > 0) {
			this.$el.findElement('promotion--container').css('display', 'table')
			const promotion = this.$el.findElement('promotion')
			if (promotion) {
				promotion.text(convertCurrency(data.promotion))
			}
		} else {
			this.$el.findElement('promotion--container').css('display', 'none')
		}
		this.$el.findElement('total').text(convertCurrency(data.total))
		this.$el
			.findElement('producttotal')
			.text(convertCurrency(data.producttotal))
		this.$el.findElement('content').html(data.html)

		run(blocks, this.$el.findElement('content'))
	}

	setLoading(loading) {
		if (loading) {
			this.$el.addModifier('loading')
		} else {
			this.$el.removeModifier('loading')
		}
	}

	viewCart() {
		this.open()
		$(document).trigger('shoppingcart:view-cart')
	}

	handleFormChange(event) {
		this.setLoading(true)

		const $form = $(event.target).parents('form')
		const data = new FormData($form.get(0))
		data.append('ajax', true)

		$.ajax({
			method: 'POST',
			processData: false,
			contentType: false,
			cache: false,
			url: $form.attr('action'),
			data: data,
		})
			.done((json) => this.render(json))
			.fail((error) => {
				$(document).trigger('message:error', error.message)
			})
			.always(() => this.setLoading(false))
	}
}
