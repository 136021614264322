import $ from 'jquery'
import Block from '../../common.blocks/block'

export default class Choices extends Block {
	static title = 'choices'

	constructor($el) {
		super($el)

		this.data = new FormData(this.$el.get(0))
		this.data.append('ajax', true)

		this.page = this.$el
			.find('.choices__options')
			.not('.choices__options--hidden')
			.index()
		this.choiceContainer = this.$el
		this.steps = this.$el.find('.choices__center')
		this.choice = $('.choices__options')
		this.buttons = this.$el.find('.choices__bottom .button')
		this.next = $('.choices__bottom .button--next')
		this.prev = $('.choices__bottom .button--prev')
		this.showChoice = $('.button--show-choice')
		this.closeChoice = $('.button--hide-choice')

		this.buttons.each((index, el) => {
			$(el).on('click', () => {
				if (this.page >= 0) {
					this.choice = $('.choices__options')
					const dataStep = 'property_id[]'

					if (this.checkRequiredValues(el, dataStep)) {
						if ($(el).hasModifier('prev')) {
							$(this.choice[this.page]).find('.choice__input').val('')
						}
						this.changeData(dataStep)
						this.changeStep(el)
						if ($(el).hasModifier('next')) {
							this.handleOptionSubmit()
							this.changed = 0
						}

						if (this.page == 1) {
							this.subCategory = $(this.choice[0])
								.find('.choice__input')
								.attr('data-link')
						}
					}
				}
			})
		})

		$(this.showChoice).on('click', () => {
			$(this.choiceContainer).removeModifier('hidden')
		})

		$(this.closeChoice).on('click', () => {
			$(this.choiceContainer).addModifier('hidden')
		})

		$(this.$el).on('click', (e) => {
			if ($(e.target).hasClass('email_send')) {
				this.handleFormSubmit()
			}
		})
	}

	checkRequiredValues(el, dataStep) {
		if (
			this.getCheckedOptionsValues(dataStep).length === 0 &&
			$(el).hasModifier('next')
		) {
			$(document).trigger('message:error', 'Deze stap is vereist')
			return false
		}
		return true
	}

	getCheckedOptionsValues(dataStep) {
		//Get all checked value of current step en put in array
		const values = []

		$(this.steps)
			.find(`.choices__options input[data-type="${dataStep}"]:checked`)
			.each(function () {
				values.push($(this).val())
			})

		return values
	}

	changeData(dataStep) {
		this.data.delete(dataStep)
		const values = this.getCheckedOptionsValues(dataStep)

		for (let i = 0; i < values.length; i++) {
			this.data.append(dataStep, values[i])
		}
	}

	changeStep(el) {
		$(this.choice[this.page]).addModifier('hidden')
		if ($(el).hasModifier('prev')) {
			$(this.choice[this.page]).remove()
			$(`.products_count .product_count:eq(${this.page})`).remove()
			$(`.choice_text:eq(${this.page})`).remove()
			$(`.choice_description:eq(${this.page})`).remove()
			this.next.removeModifier('disabled')
			this.page = this.page - 1
			$(`.products_count .product_count:eq(${this.page})`).removeModifier(
				'hidden',
			)

			$(`.choice_text:eq(${this.page})`).removeModifier('hidden')

			$(`.choice_description:eq(${this.page})`).removeModifier('hidden')
		} else if ($(el).hasModifier('next')) {
			const choiceElem = $('<div/>').addClass(
				'choices__options choices__options--hidden',
			)

			$(this.choice[this.page]).after(choiceElem)
			this.page = this.page + 1
			this.choice[this.page] = choiceElem
		}

		this.data.set('page', this.page)

		if (this.page > 0) {
			this.prev.removeModifier('disabled')
		} else {
			this.prev.addModifier('disabled')
		}

		$(this.choice[this.page]).removeModifier('hidden')

		this.checkDisableButton()
	}

	checkDisableButton() {
		this.page > 0
			? this.prev.removeModifier('disabled')
			: this.prev.addModifier('disabled')
	}

	// setLoading(loading) {
	// 	if (loading) {
	// 		$('.choices__overlay').addModifier('loading')
	// 	} else {
	// 		$('.choices__overlay').removeModifier('loading')
	// 	}
	// }

	handleOptionSubmit() {
		this.data.set('add', 1)
		this.data.set('ajax', 1)
		this.data.set('category', $('.choices__center').attr('data-choice'))
		// this.setLoading(true)
		$(this.choice[this.page]).empty()

		$.ajax({
			method: 'POST',
			processData: false,
			contentType: false,
			cache: false,
			url: '/choice',
			data: this.data,
			traditional: true,
		})
			.done((json) => {
				// check for errors
				if (json.is_product) {
					this.next.addModifier('disabled')

					$('.choices .sitemap__link').attr('href', json.property_url)
					$('.choices .sitemap__link').removeModifier('hidden')
				}

				if (!json.error) {
					$(this.choice[this.page]).html(json.html)

					const previousSelector = `:eq(${this.page - 1})`
					const currentSelector = `:eq(${this.page})`

					const productCount = $('<strong/>').addClass('product_count')
					const choiceText = $('<strong/>').addClass('choice_text')
					const choiceDescription = $('<span/>').addClass('choice_description')

					$(`.products_count .product_count${previousSelector}`).addModifier(
						'hidden',
					)
					$(`.choice_description${previousSelector}`).addModifier('hidden')
					$(`.choice_text${previousSelector}`).addModifier('hidden')

					$(`.products_count .product_count${previousSelector}`).after(
						productCount,
					)

					$(`.choice_text${previousSelector}`).after(choiceText)

					$(`.choice_description${previousSelector}`).after(choiceDescription)

					$(`.products_count .product_count${currentSelector}`).text(
						json.count + ' producten gevonden',
					)

					$(`.choice_text${currentSelector}`).text(json.property_name)

					$(`.choice_description${currentSelector}`).text(
						json.property_description,
					)
				}
			})
			.fail((error) => {
				$(document).trigger('message:error', error.message)
			})
		// .always(() => this.setLoading(false))
	}

	handleFormSubmit() {
		this.data.set('send_email', 1)
		// this.setLoading(true)
		$.ajax({
			method: 'POST',
			processData: false,
			contentType: false,
			cache: false,
			url: '/choice',
			data: this.data,
			traditional: true,
		})
			.done((json) => {
				// check for errors
				if (!json.error) {
					//
				}
			})
			.fail((error) => {
				$(document).trigger('message:error', error.message)
			})
	}
}
