import '../../bem'

export default class Block {
	constructor($el) {
		this.$el = $el
	}

	isValidBlock($el) {
		if ($el.length === 0) {
			return false
		}

		return typeof $el.get(0).ref !== 'undefined'
	}

	handleError(error) {
		if (process.env.NODE_ENV === 'development') {
			// eslint-disable-next-line no-console
			console.error(error)
		}

		$(document).trigger('message:error', error.toString())
	}
}
