import $ from 'jquery'

import Block from '../block'

export default class Message extends Block {
	static title = 'message'

	constructor($el) {
		super($el)

		// close the error when the user presses the close button
		this.$el.findElement('close').click(() => {
			this.$el.removeModifier('visible')
		})

		// errors can be sent from other blocks, display these errors
		$(document).on('message:error', (event, data) =>
			this.handleMessage('error', data),
		)
		$(document).on('message:success', (event, data) =>
			this.handleMessage('success', data),
		)
	}

	handleMessage(type, data) {
		window.scrollTo({
			behavior: 'smooth',
			top: this.$el.position().top,
		})

		this.$el.addModifier('visible').addModifier(type).html(data)

		// automatically hide message after 10 seconds
		setTimeout(() => {
			this.$el.removeModifier('visible').removeModifier(type)
		}, 10000)
	}
}
