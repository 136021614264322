import Block from '../../common.blocks/block'
import $ from 'jquery'

export default class Notify extends Block {
	static title = 'notify'

	constructor($el) {
		super($el)

		$(document).on('size:change', (event, data) =>
			this.handleSizeChange(event, data),
		)

		$(document).on('color:change', (event, data) => {
			const color = this.$el.find('[name="notify_color_id"]')
			color.val(data.id)
		})
	}

	handleSizeChange(event, data) {
		if (data.stock < 1 && data.availability != 'presale') {
			this.$el.removeModifier('hidden')
		} else {
			this.$el.addModifier('hidden')
		}

		const size = this.$el.find('[name="notify_size_id"]')
		size.val(data.id)
	}
}
