import $ from 'jquery'

import Block from '../../common.blocks/block'

export default class SmoothScroll extends Block {
	static title = 'smooth-scroll'

	constructor($el) {
		super($el)

		this.$el.on('click', (e) => {
			e.preventDefault()

			$(this.$el.attr('href')).get(0).scrollIntoView({
				behavior: 'smooth',
			})
		})
	}
}
