import 'core-js/stable'
import 'regenerator-runtime/runtime'
import aos from 'aos'

import './bem'

aos.init({
	duration: 600,
})

// load the styling
import './styling/_index.scss'

// load the common blocks
import * as blocks from './common.blocks'
import * as customBlocks from './custom.blocks'

// load the run script
import run from './scripts/run'

run({ ...blocks, ...customBlocks })
