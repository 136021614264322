import Block from '../../common.blocks/block'

export default class Calendly extends Block {
	static title = 'calendly'

	static popupUrl =
		'https://calendly.com/zorgtotaalwinkel/showroombezoek?hide_event_type_details=1&hide_gdpr_banner=1&background_color=f9faf5&text_color=222222&primary_color=8aa84b'

	constructor($el) {
		super($el)

		window.CalendlyPopupUrl = Calendly.popupUrl

		this.$el.on('click', () => {
			window.Calendly.initPopupWidget({ url: Calendly.popupUrl })
			return false
		})
	}
}
