import $ from 'jquery'
import Popup from '../popup'

export default class RecaptchaPopup extends Popup {
	static title = 'recaptcha-popup'

	getDependency() {
		return 'https://www.google.com/recaptcha/api.js?render=explicit'
	}

	load() {
		window.grecaptcha.ready(() => {
			window.grecaptcha.render(this.$el.get(0), {
				sitekey: this.$el.data('sitekey'),
			})
		})
	}

	constructor($el) {
		super($el)

		$(document).on('newsletter:submit', (event, data) => {
			super.open()

			if (typeof this.interval !== 'undefined') {
				window.clearInterval(this.interval)
			}

			this.interval = window.setInterval(() => {
				if (window.grecaptcha?.getResponse().length > 0 && data.form) {
					data.form.submit()
					window.clearInterval(this.interval)
				}
			}, 1000)
		})
	}
}
