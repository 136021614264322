import $ from 'jquery'

import Block from '../block'

export default class ShoppingcartTable extends Block {
	static title = 'shoppingcart-table'

	constructor($el) {
		super($el)

		if (this.$el.hasModifier('cart')) {
			this.rows = this.$el.findElement('row')

			this.rows.each((index, element) => {
				this.deleteButton = $(element).find('.shoppingcart-table__delete')
				this.deleteButton.on('click', () => {
					let id = $(element).attr('data-product-id')
					this.removeItem(id)
				})
			})
		}
	}

	removeItem(id) {
		const url = '/shoppingcart/delete/' + id

		$.ajax({
			url: url,
			method: 'DELETE',
			success: function () {
				$(document).trigger('shoppingcart:remove-from-cart', 'shoppingcart')
				window.location.reload()
			},
			error: function () {
				//
			},
		})
	}
}
